import { useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

export function useAccessToken(): {
  getAccessToken: () => Promise<string | void>
} {
  const { getAccessTokenSilently } = useAuth0()

  const getAccessToken = useCallback(async (): Promise<string | void> => {
    return getAccessTokenSilently().catch(() =>
      console.error('Failed to get access token'),
    )
  }, [getAccessTokenSilently])

  return {
    getAccessToken,
  }
}
