import { t } from 'i18next'
import { z } from 'zod'

import { phoneRegex } from '~/validation/regex'

export const profileEditSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  name: z.string().min(1, {
    message: t('message.required', {
      entity: t('pages.members.label.name'),
    }),
  }),
  language: z.coerce.number(),
  phoneNumber: z
    .string()
    .optional()
    .refine(
      (val) => val === undefined || val === '' || phoneRegex.test(val),
      t('message.invalidPhoneNumber') ?? '',
    ),
  mobileNumber: z
    .string()
    .optional()
    .refine(
      (val) => val === undefined || val === '' || phoneRegex.test(val),
      t('message.invalidPhoneNumber') ?? '',
    ),
})

export type ProfileEditSchema = z.infer<typeof profileEditSchema>
