// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file bff/v1/common.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetMemberProfileRequest, GetMemberProfileResponse, GetOrganizationRequest, GetOrganizationResponse, GetPlanRequest, GetPlanResponse, GetProductByPlanIdRequest, GetProductByPlanIdResponse, ListMembersRequest, ListMembersResponse, ListProductsRequest, ListProductsResponse, ListSelectableUserRolesRequest, ListSelectableUserRolesResponse, ListUserRolesRequest, ListUserRolesResponse, ResetPasswordRequest, ResetPasswordResponse, SaveMemberProfileRequest, SaveMemberProfileResponse, UploadMemberImageRequest, UploadMemberImageResponse } from "./common_pb.js";

/**
 * @generated from rpc bff.v1.BffCommonService.ListProducts
 */
export const listProducts = {
  localName: "listProducts",
  name: "ListProducts",
  kind: MethodKind.Unary,
  I: ListProductsRequest,
  O: ListProductsResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.GetProductByPlanId
 */
export const getProductByPlanId = {
  localName: "getProductByPlanId",
  name: "GetProductByPlanId",
  kind: MethodKind.Unary,
  I: GetProductByPlanIdRequest,
  O: GetProductByPlanIdResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.ListUserRoles
 */
export const listUserRoles = {
  localName: "listUserRoles",
  name: "ListUserRoles",
  kind: MethodKind.Unary,
  I: ListUserRolesRequest,
  O: ListUserRolesResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.ListSelectableUserRoles
 */
export const listSelectableUserRoles = {
  localName: "listSelectableUserRoles",
  name: "ListSelectableUserRoles",
  kind: MethodKind.Unary,
  I: ListSelectableUserRolesRequest,
  O: ListSelectableUserRolesResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.GetMemberProfile
 */
export const getMemberProfile = {
  localName: "getMemberProfile",
  name: "GetMemberProfile",
  kind: MethodKind.Unary,
  I: GetMemberProfileRequest,
  O: GetMemberProfileResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.SaveMemberProfile
 */
export const saveMemberProfile = {
  localName: "saveMemberProfile",
  name: "SaveMemberProfile",
  kind: MethodKind.Unary,
  I: SaveMemberProfileRequest,
  O: SaveMemberProfileResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.GetOrganization
 */
export const getOrganization = {
  localName: "getOrganization",
  name: "GetOrganization",
  kind: MethodKind.Unary,
  I: GetOrganizationRequest,
  O: GetOrganizationResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.ResetPassword
 */
export const resetPassword = {
  localName: "resetPassword",
  name: "ResetPassword",
  kind: MethodKind.Unary,
  I: ResetPasswordRequest,
  O: ResetPasswordResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.GetPlan
 */
export const getPlan = {
  localName: "getPlan",
  name: "GetPlan",
  kind: MethodKind.Unary,
  I: GetPlanRequest,
  O: GetPlanResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.ListMembers
 */
export const listMembers = {
  localName: "listMembers",
  name: "ListMembers",
  kind: MethodKind.Unary,
  I: ListMembersRequest,
  O: ListMembersResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffCommonService.UploadMemberImage
 */
export const uploadMemberImage = {
  localName: "uploadMemberImage",
  name: "UploadMemberImage",
  kind: MethodKind.Unary,
  I: UploadMemberImageRequest,
  O: UploadMemberImageResponse,
  service: {
    typeName: "bff.v1.BffCommonService"
  }
} as const;
