import { FC, memo } from 'react'

import { Flex, Image, Text, Tooltip } from '@chakra-ui/react'

import { useSideMenuCollapsed, useTheme } from '@enechain/ecloud-designsystem'

export type SideMenuLayoutOrganizationProps = {
  name: string
  src?: string
}

const SideMenuLayoutOrganization: FC<SideMenuLayoutOrganizationProps> = ({
  name,
  src,
}: SideMenuLayoutOrganizationProps) => {
  const theme = useTheme()
  const { collapsed } = useSideMenuCollapsed()

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      gap={theme.space[2]}
      width={collapsed === false ? 'full' : '32px'}
      marginBottom={theme.space[2]}
      padding={0}
    >
      <Image
        width="32px"
        height="32px"
        margin={0}
        padding={0}
        borderRadius={theme.radii.radiiBase}
        aria-hidden
        fallbackSrc="https://storage.googleapis.com/enec-eauth-prod-bucket/organization/default"
        fallbackStrategy="onError"
        src={src}
      />
      {collapsed === false && (
        <Tooltip
          background={theme.colors.gray['900']}
          borderRadius={theme.radii.radiiBase}
          label={name}
          placement="top-end"
        >
          <Text
            overflow="hidden"
            {...theme.textStyles.textSm}
            color={theme.semanticTokens.colors.Text.highEmphasis}
            fontWeight="bold"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
        </Tooltip>
      )}
    </Flex>
  )
}

export default memo(SideMenuLayoutOrganization)
