import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { SecondaryButton } from '@enechain/ecloud-designsystem'

type Props = {
  onClick: () => void
}

const CancelButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <SecondaryButton onClick={onClick}>{t('action.cancel')}</SecondaryButton>
  )
}

export default React.memo(CancelButton)
