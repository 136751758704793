import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { MatchRoute, useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import {
  SideMenuLayout,
  SideMenuLayoutAppSwitcherMenu,
  SideMenuLayoutMenuItem,
  SideMenuLayoutPopOverMenuItem,
  SideMenuLayoutSecondaryMenuProvider,
  SideMenuLayoutUserMenu,
  useSideMenuCollapsed,
} from '@enechain/ecloud-designsystem'
import {
  ECloudHorizontal,
  ECloudSymbol,
  EnecAppswitcherRegular,
  FaAddressCardRegular,
  FaCircleQuestionRegular,
  FaHouseRegular,
  FaUserRegular,
} from '@enechain/ecloud-icons'

import { useLoginOrganization } from '~/hooks/useLoginOrganization'
import { useLoginUser } from '~/hooks/useLoginUser'
import { useListProducts } from '~/pages/Home/hooks/useProduct'

import FullLoadingView from './FullLoadingView'
import SideMenuLayoutOrganization from './SideMenuLayoutOrganization'

export const Logo: React.FC = () => {
  const { collapsed } = useSideMenuCollapsed()

  if (collapsed) {
    return <ECloudSymbol width={38} height={48} aria-hidden />
  }
  return <ECloudHorizontal width={110} height={48} aria-hidden />
}

export const TopMenu: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const organization = useLoginOrganization()
  return (
    <React.Suspense fallback={<FullLoadingView />}>
      <SideMenuLayoutOrganization
        name={organization.name}
        src={organization.imageUrl + '?' + new Date().toISOString()}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaHouseRegular}
        isActive={MatchRoute({ to: '/', fuzzy: true }) === undefined}
        label={t('pages.home.title')}
        onClick={() => {
          void navigate({
            to: '/',
          })
        }}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaUserRegular}
        isActive={MatchRoute({ to: '/members', fuzzy: true }) === undefined}
        label={t('pages.members.list')}
        onClick={() => {
          void navigate({
            to: '/members',
          })
        }}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaAddressCardRegular}
        isActive={MatchRoute({ to: '/profile', fuzzy: true }) === undefined}
        label={t('pages.profile.title')}
        onClick={() => {
          void navigate({
            to: '/profile',
          })
        }}
      />
    </React.Suspense>
  )
}

export const BottomMenu: React.FC = () => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const me = useLoginUser()
  const products = useListProducts()
  const onClickSignOut = (): void => {
    void (async (): Promise<void> => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    })()
  }
  return (
    <>
      <SideMenuLayoutMenuItem
        IconLeft={FaCircleQuestionRegular}
        label={t('pages.home.label.helpCenter')}
        onClick={(): void => {
          window.open(import.meta.env.VITE_SUPPORT_PAGE_URL, '_blank')
        }}
      />
      <SideMenuLayoutAppSwitcherMenu
        Icon={EnecAppswitcherRegular}
        items={products.map((product) => {
          return {
            name: product.name,
            onClick: () => {
              window.open(product.url, '_blank')
            },
          }
        })}
        label={t('pages.home.label.appSwitcher')}
      />
      <SideMenuLayoutUserMenu
        popOverMenu={
          <SideMenuLayoutPopOverMenuItem
            label={t('action.logout')}
            onClick={onClickSignOut}
          />
        }
        userIconSrc={me.imageUrl + '?' + new Date().toISOString()}
        userName={me.name}
      />
    </>
  )
}

export const MenuLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <React.Suspense fallback={<FullLoadingView />}>
      <SideMenuLayoutSecondaryMenuProvider>
        <SideMenuLayout
          bottomMenu={BottomMenu}
          defaultCollapsed={false}
          defaultMenuLock
          logo={Logo}
          topMenu={TopMenu}
        >
          {children}
        </SideMenuLayout>
      </SideMenuLayoutSecondaryMenuProvider>
    </React.Suspense>
  )
}
