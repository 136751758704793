import { useQuery } from '@connectrpc/connect-query'

import { listProducts } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { Product } from '~/gen/proto/bff/v1/model_pb'

export function useListProducts(): Product[] {
  const { data } = useQuery(listProducts)
  if (!data) {
    return []
  }
  return data.products
}
