import { useNavigate } from '@tanstack/react-router'
import { useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import MemberEditView from '~/pages/Members/components/MemberEdit'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { MemberPageInnerLayout } from '~/pages/Members/components/layouts/Inner'
import { memberAtom } from '~/pages/Members/state/memberMutation'
import { MemberEditRoute } from '~/routes/_authenticated/members/$memberId/edit'

import { useMember } from './hooks/useMember'

export const MemberEdit: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const resetEditingMember = useSetAtom(memberAtom)
  const memberId = MemberEditRoute.useParams().memberId
  const member = useMember(memberId)
  const editing = useAtomValue(memberAtom)[memberId] ?? member

  const onNext = (): void => {
    void navigate({
      to: `/members/${memberId}/confirm`,
      params: {
        memberId,
      },
    })
  }

  const onCancel = (): void => {
    void navigate({
      to: `/members/${memberId}`,
      params: {
        memberId,
      },
    })
    resetEditingMember({})
  }

  const breadcrumbItems = [
    {
      label: editing.name,
      onClick: () =>
        navigate({
          to: `/members/$memberId`,
          params: {
            memberId: editing.id,
          },
        }),
    },
    {
      label: t('action.edit'),
    },
  ]

  return (
    <MemberPageHeader breadcrumbItems={breadcrumbItems}>
      <MemberPageInnerLayout>
        <MemberEditView member={editing} onCancel={onCancel} onNext={onNext} />
      </MemberPageInnerLayout>
    </MemberPageHeader>
  )
}
