import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { listSelectableUserRoles } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { SelectableUserRole } from '~/gen/proto/bff/v1/common_pb'

export function useSelectableUserRoles(): SelectableUserRole[] {
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    listSelectableUserRoles,
    {},
    {
      select: (data) => data.selectableUserRoles,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.userRole') }))
  }
  return res.data
}
