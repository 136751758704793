import { createFileRoute } from '@tanstack/react-router'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { MemberDetail } from '~/pages/Members/detail'

export const Route = createFileRoute('/_authenticated/members/$memberId/')({
  component: () => (
    <OrganizationAdmin thorwError>
      <MemberDetail />
    </OrganizationAdmin>
  ),
})

export const MemberDetailRoute = Route
