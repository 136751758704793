import * as React from 'react'

import i18next from 'i18next'

import { Language } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'

export const I18nProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode => {
  const me = useLoginUser()
  React.useEffect(() => {
    void i18next.changeLanguage(getLngString(me.language))
  }, [me.language])
  return children
}

function getLngString(language: Language): string {
  switch (language) {
    case Language.ENGLISH:
      return 'en'
    case Language.JAPANESE:
      return 'ja'
    default:
      console.warn('Invalid Language Setting. Fallbacked to Japanese')
      return 'ja'
  }
}
