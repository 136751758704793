import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { listUserRoles } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { UserRole } from '~/gen/proto/bff/v1/model_pb'

export function useUserRoles(planId: number): UserRole[] {
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    listUserRoles,
    { planId },
    {
      select: (data) => data.userRoles,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.userRole') }))
  }
  return res.data
}
