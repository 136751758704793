import * as React from 'react'

import { useNavigate } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

import { Flex, Spacer } from '@chakra-ui/react'

import { Spacing, TertiaryButton } from '@enechain/ecloud-designsystem'

import { Member, MemberStatus } from '~/gen/proto/bff/v1/model_pb'
import SendInvitationButton from '~/pages/Members/components/MembersTable/SendInvitationButton'
import { dict } from '~/pages/Members/dict'
import { fromPbDate } from '~/utils/date'

import { MemberStatusTag } from './MemberStatus'

export class MemberRow {
  public name: string
  public email: string
  public role: string
  public status: JSX.Element
  public createdAt: string
  public rest: JSX.Element | undefined
  constructor(member: Member, rest?: JSX.Element) {
    this.name = member.name
    this.email = member.email
    this.role = dict.role(member.role)
    this.status = <MemberStatusTag status={member.status} />
    this.createdAt = member.createdAt
      ? fromPbDate(member.createdAt).formatYYYYMMDD()
      : ''
    this.rest = rest
  }
}
const columnHelper = createColumnHelper<MemberRow>()
export const memberTableColumns = Object.keys(new MemberRow(new Member())).map(
  (key) => {
    return columnHelper.accessor(key as keyof MemberRow, {
      cell: (info) => info.getValue(),
      header: key === 'rest' ? '' : t(`pages.members.label.${key}`),
      enableSorting: false,
      meta: {
        headerStyle: {
          maxWidth: key === 'rest' ? '350px' : '180px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        cellStyle: {
          maxWidth: key === 'rest' ? '350px' : '180px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    })
  },
)

type RowProps = {
  member: Member
  onClickResendInvitation: () => void
}
const MembersTableRow: React.FC<RowProps> = ({
  member,
  onClickResendInvitation,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Flex justifyContent="flex-end" gap={Spacing[4]}>
      {member.status === MemberStatus.INVITED ? (
        <SendInvitationButton onClick={onClickResendInvitation}>
          {t('pages.members.action.resendInvitation.button')}
        </SendInvitationButton>
      ) : (
        <Spacer />
      )}
      <TertiaryButton
        onClick={() =>
          navigate({
            to: `/members/${member.id}`,
          })
        }
      >
        {t('action.detail')}
      </TertiaryButton>
    </Flex>
  )
}

export default React.memo(MembersTableRow)
