import { StrictMode } from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom/client'

import '~/i18n'
import { AppChakraProvider } from '~/provider/AppChakraProvider'
import { AppProvider } from '~/provider/AppProvider'
import { AppRouteProvider } from '~/provider/AppRouteProvider'
import { HeadProvider } from '~/provider/HeadProvider'

const rootElement = document.getElementById('root')
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <StrictMode>
      <HeadProvider>
        <Auth0Provider
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          }}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
        >
          <AppChakraProvider>
            <AppProvider>
              <AppRouteProvider />
            </AppProvider>
          </AppChakraProvider>
        </Auth0Provider>
      </HeadProvider>
    </StrictMode>,
  )
}
