import * as React from 'react'

import { ChakraProvider } from '@chakra-ui/provider'
import { ToastProvider } from '@chakra-ui/react'

import { appTheme } from '~/theme/theme'

type Props = {
  children: React.ReactNode
}

export const AppChakraProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <ChakraProvider theme={appTheme}>
      <ToastProvider />
      {children}
    </ChakraProvider>
  )
}
