import * as React from 'react'

import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormErrorMessage, Text } from '@chakra-ui/react'

import {
  Label,
  Link,
  Select,
  Spacing,
  Term,
} from '@enechain/ecloud-designsystem'
import { FaArrowUpRightFromSquareRegular } from '@enechain/ecloud-icons'

import { Language, License, UserRoleSetting } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'
import { MemberEditSchema } from '~/pages/Members/components/MemberEdit/memberEditSchema'

type Props = {
  formMethods: UseFormReturn<MemberEditSchema>
  userRoleSetting: UserRoleSetting[]
  index: number
  license: License
}

export const UserRoleSelect: React.FC<Props> = ({
  formMethods,
  userRoleSetting,
  license,
  index,
}) => {
  const { t } = useTranslation()
  const {
    formState: { errors },
  } = formMethods
  const me = useLoginUser()
  const isEng = me?.language === Language.ENGLISH
  const { planCode, planId, planName, engPlanName, productName } = license
  const options = userRoleSetting.map((setting) => ({
    label: isEng ? setting.userRoleEngName : setting.userRoleName,
    value: setting.userRoleCode,
    planCode,
    isSetting: setting.isSetting,
    isDefault: setting.isDefault,
  }))
  const settingRoles = options.filter((setting) => setting.isSetting)
  const defaultValue =
    settingRoles.length > 0
      ? settingRoles
      : options.filter((setting) => setting.isDefault)

  return (
    <Controller
      control={formMethods.control}
      defaultValue={defaultValue}
      name={`userRoleSettings.${index}`}
      render={({ field: { onChange, value } }): React.ReactElement => (
        <FormControl
          hidden={
            license.isUserRoleConfigurable === false ||
            license.isProductPublic === false
          }
          isInvalid={
            errors.userRoleSettings &&
            errors.userRoleSettings[index] !== undefined
          }
        >
          <Term>
            <Label
              required
            >{`${productName} / ${isEng ? engPlanName : planName}`}</Label>
            <Select
              isMulti
              onChange={onChange}
              options={options}
              selectedOptions={value}
            />
            <Link
              alignItems="center"
              gap={Spacing[1]}
              display="flex"
              href={`/user_roles/${planId}`}
              isExternal
            >
              <Text>{t('pages.members.link.user_role_page')}</Text>
              <FaArrowUpRightFromSquareRegular />
            </Link>

            {errors.userRoleSettings && (
              <FormErrorMessage>
                {errors.userRoleSettings[index]?.message}
              </FormErrorMessage>
            )}
          </Term>
        </FormControl>
      )}
    />
  )
}
