import * as React from 'react'

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { t } from 'i18next'
import { Translation } from 'react-i18next'

import { DataTable, Th } from '@enechain/ecloud-designsystem'

import i18n from '~/i18n'
import { useUserRoles } from '~/pages/UserRoles/hooks/useUserRole'
import { UserRoleDetailRoute } from '~/routes/_authenticated/user_roles/$planId'

type UserRoleRow = {
  name: string
  memo: string
}

const columnHelper = createColumnHelper<UserRoleRow>()
export const columns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: t('pages.user_roles.label.name'),
    enableSorting: false,
    meta: {
      headerStyle: {
        width: '30%',
      },
      cellStyle: {
        width: '30%',
      },
    },
  }),
  columnHelper.accessor('memo', {
    cell: (info) => info.getValue(),
    header: t('pages.user_roles.label.memo'),
    enableSorting: false,
  }),
]

export const UserRolesTable: React.FC = () => {
  const planId = UserRoleDetailRoute.useParams().planId
  const userRoles = useUserRoles(Number(planId))
  const data = userRoles.map((userRole) => ({
    name: i18n.language === 'ja' ? userRole.name : userRole.engName,
    memo: i18n.language === 'ja' ? userRole.memo : userRole.engMemo,
  }))
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <DataTable
      renderHeader={(header): JSX.Element => {
        return (
          <Translation key={header.column.id}>
            {(t) => <Th>{t(`pages.user_roles.label.${header.column.id}`)}</Th>}
          </Translation>
        )
      }}
      table={table}
    />
  )
}

export default React.memo(UserRolesTable)
