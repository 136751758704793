import { useRouterState } from '@tanstack/react-router'

export const KarteEventView = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const routerState = useRouterState()
  if (routerState.status === 'idle' && routerState.loadedAt !== 0) {
    try {
      krt('send', 'view')
    } catch (e) {
      console.error(e)
    }
  }

  return children
}
