import { useNavigate } from '@tanstack/react-router'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import { Flex, Image, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  PrimaryButton,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaUserRegular } from '@enechain/ecloud-icons'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { useLoginOrganization } from '~/hooks/useLoginOrganization'
import { useLoginUser } from '~/hooks/useLoginUser'
import MembersTable from '~/pages/Members/components/MembersTable'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { memberAtom } from '~/pages/Members/state/memberMutation'

import { MemberInfomation } from './components/MemberInfomation'

export const Members: React.FC = () => {
  const organization = useLoginOrganization()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const resetEditingMember = useSetAtom(memberAtom)
  const onClickAddMember = (): void => {
    resetEditingMember({})
    void navigate({
      to: '/members/create',
    })
  }
  const me = useLoginUser()
  const hasLicense = Object.keys(me.baseLicenseMap).length > 0

  return (
    <MemberPageHeader>
      <Flex
        flexDirection="column"
        gap={Spacing[3]}
        maxWidth="1280px"
        margin="auto"
        padding={Spacing[2]}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap={Spacing[2]}>
            <Image
              width="40px"
              borderRadius={BorderRadius.radiiMd}
              fallbackSrc="https://storage.googleapis.com/enec-eauth-prod-bucket/organization/default"
              fallbackStrategy="onError"
              src={organization.imageUrl}
            />
            <Text
              {...Typography.headerLg}
              color={SemanticColors.Text['highEmphasis']}
              textAlign="left"
            >
              {organization.name}
            </Text>
          </Flex>
          <OrganizationAdmin>
            {hasLicense && (
              <PrimaryButton
                leftIcon={<FaUserRegular boxSize="24px" />}
                onClick={onClickAddMember}
              >
                {t('pages.members.action.inviteMember.button')}
              </PrimaryButton>
            )}
          </OrganizationAdmin>
        </Flex>
        <MemberInfomation />
        <MembersTable />
      </Flex>
    </MemberPageHeader>
  )
}
