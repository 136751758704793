import React, { Suspense } from 'react'

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { KarteEventView } from '@ecloud/tag'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { ErrorBoundary } from 'react-error-boundary'

import { Error } from '@enechain/ecloud-designsystem'

import FullLoadingView from '~/components/layout/FullLoadingView'
import { MenuLayout } from '~/components/layout/Menu'
import { I18nProvider } from '~/i18n/i18nProvider'
import { GTMDataLayerProvider } from '~/provider/GTMDataLayerProvider'

const Main = (): JSX.Element => {
  const { reset } = useQueryErrorResetBoundary()
  const { logout } = useAuth0()
  const fallbackComponent = ({
    error,
    resetErrorBoundary,
  }: {
    error: Error
    resetErrorBoundary: () => void
  }): React.ReactElement => (
    <Error
      error={{ message: error.message }}
      logout={(): void => {
        void logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      }}
      resetErrorBoundary={resetErrorBoundary}
    />
  )

  return (
    <ErrorBoundary FallbackComponent={fallbackComponent} onReset={reset}>
      <Suspense fallback={<FullLoadingView />}>
        <GTMDataLayerProvider>
          <KarteEventView>
            <I18nProvider>
              <MenuLayout>
                <Outlet />
              </MenuLayout>
            </I18nProvider>
          </KarteEventView>
        </GTMDataLayerProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export const Route = createFileRoute('/_authenticated')({
  component: withAuthenticationRequired(Main, {
    onRedirecting: () => <FullLoadingView />,
    loginOptions: {
      appState: {
        returnTo: window.location.href,
      },
    },
  }),
})
