import * as React from 'react'

import { useMutation } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Box, Flex, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  ModalView,
  PrimaryButton,
  SemanticColors,
  Spacing,
  Typography,
  useToast,
} from '@enechain/ecloud-designsystem'

import CancelButton from '~/components/parts/CancelButton'
import { sendInvitation } from '~/gen/proto/bff/v1/admin-BffAdminService_connectquery'
import { Member } from '~/gen/proto/bff/v1/model_pb'

type Props = {
  isOpen: boolean
  onClose: () => void
  member: Member
}

const ReSendInvitationModal: React.FC<Props> = ({
  member,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.members',
  })
  const { showToast } = useToast()

  const { mutate, isPending: isSendingInvitation } = useMutation(
    sendInvitation,
    {
      onSuccess: () => {
        showToast({
          toastTitle: t('action.resendInvitation.success'),
          toastMessage: t('action.resendInvitation.success_to', {
            email: member.email,
          }),
          status: 'success',
        })
      },
      onError: () => {
        showToast({
          toastTitle: t('action.resendInvitation.error'),
          toastMessage: t('action.resendInvitation.error_to', {
            email: member.email,
          }),
          status: 'error',
        })
      },
    },
  )

  const onSubmit = (): void => {
    void mutate({
      memberId: member.id,
    })
    onClose()
  }
  return (
    <ModalView
      bodyChildren={
        <Flex flexDirection="column" gap={Spacing[4]}>
          <Text>{t('action.resendInvitation.description')}</Text>
          <Box
            padding={Spacing[4]}
            border="1px"
            borderColor={SemanticColors.Border.midEmphasis}
            borderRadius={BorderRadius.radiiMd}
          >
            <Text {...Typography.headerMd}>{member.name}</Text>
            <Text>{member.email}</Text>
          </Box>
        </Flex>
      }
      footerChildren={
        <Flex justifyContent="flex-end" gap={Spacing[2]}>
          <CancelButton onClick={onClose} />
          <PrimaryButton isLoading={isSendingInvitation} onClick={onSubmit}>
            {t('action.resendInvitation.button')}
          </PrimaryButton>
        </Flex>
      }
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      title={t('action.resendInvitation.title')}
    />
  )
}

export default React.memo(ReSendInvitationModal)
