import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import { Loading } from '@enechain/ecloud-designsystem'

const FullLoadingView: React.FC = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100wh"
    height="100vh"
  >
    <Loading />
  </Flex>
)

export default FullLoadingView
