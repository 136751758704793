import * as React from 'react'

import { Image } from '@chakra-ui/react'

import { BorderRadius } from '@enechain/ecloud-designsystem'

type Props = {
  src: string
}

export const ProfilePicture: React.FC<Props> = ({ src }) => {
  return (
    <Image
      boxSize="64px"
      borderRadius={BorderRadius.radiiFull}
      alt="Member Image"
      fallbackSrc="/default_profile_picture.png"
      fallbackStrategy="onError"
      src={src}
    />
  )
}
