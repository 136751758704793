import { useSuspenseQuery } from '@connectrpc/connect-query'
import { t } from 'i18next'

import { getMemberProfile } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { Member } from '~/gen/proto/bff/v1/model_pb'

import { STALE_TIME } from './config'

export function useLoginUser(): Member {
  const res = useSuspenseQuery(
    getMemberProfile,
    {},
    {
      select: (data) => data.member,
      staleTime: STALE_TIME,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.member') }))
  }
  return res.data
}
