import { useEffect } from 'react'

import { useLoginUser } from '~/hooks/useLoginUser'
import { dict } from '~/pages/Members/dict'

type Props = {
  children: React.ReactNode
}
export const GTMDataLayerProvider: React.FC<Props> = ({ children }) => {
  const me = useLoginUser()
  useEffect(() => {
    // Set GTM data layer
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'identify',
      user_id: me.id,
    })
    window.dataLayer.push({
      event: 'attribute',
      company_id: me.companyId,
      organization_id: me.organizationId,
      org_role: dict.role(me.role),
    })
  }, [me])
  return children
}
