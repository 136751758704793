import * as React from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  containerId: string
}

export const GoogleTagManager: React.FC<Props> = ({ containerId }) => {
  if (containerId === '') {
    return null
  }
  return (
    <Helmet>
      <script>
        {`
        (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', "${containerId}");
      `}
      </script>
    </Helmet>
  )
}
