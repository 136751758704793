import { atom, useSetAtom } from 'jotai'

import { Member } from '~/gen/proto/bff/v1/model_pb'
import { MemberEditSchema } from '~/pages/Members/components/MemberEdit/memberEditSchema'

type MemberAtom = {
  [id: string]: Member
}
export const memberAtom = atom<MemberAtom>({})

export const useSetMember = (
  member: Member,
): ((member: MemberEditSchema) => void) => {
  // ユーザーロールが深い階層構造になっており、他のコンポーネントのメンバーオブジェクトを更新する可能性があるため、deep copyする
  member = structuredClone(member)
  const set = useSetAtom(memberAtom)
  return (formValue: MemberEditSchema) => {
    set(() => {
      return {
        [member.id]: new Member({
          ...member,
          ...formValue,
          userRoleCodes: formValue.userRoleSettings
            .flat()
            .map((role) => role.value),
        }),
      }
    })
  }
}
