import * as React from 'react'

import { useLocation, useNavigate } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

import { SideMenuLayoutBreadcrumbItem } from '@enechain/ecloud-designsystem'

import MemberConfirmView from '~/pages/Members/components/MemberConfirm'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { MemberPageInnerLayout } from '~/pages/Members/components/layouts/Inner'
import { memberAtom } from '~/pages/Members/state/memberMutation'
import { MemberConfirmRoute } from '~/routes/_authenticated/members/$memberId/confirm'

export const MemberConfirm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const editing = useAtomValue(memberAtom)
  const { memberId } = MemberConfirmRoute.useParams()
  const member = editing[memberId]
  if (!member) {
    // 確認画面へ直接アクセスするかリロードした場合は、表示データがないため、別ページへリダイレクトする
    void window.open(`/members/${memberId}`, '_self')
  }

  const location = useLocation()
  window.onbeforeunload = (e: BeforeUnloadEvent): void => {
    if (location.pathname === `/members/${memberId}/confirm`) {
      e.preventDefault()
    }
  }
  const onBack = (): void => {
    void navigate({
      to: `/members/${memberId}/edit`,
      params: {
        memberId,
      },
    })
  }
  const breadcrumbItems: SideMenuLayoutBreadcrumbItem[] = [
    {
      label: member.name,
      onClick: () =>
        navigate({
          to: `/members/$memberId`,
          params: {
            memberId: member.id,
          },
        }),
    },
    {
      label: t('action.edit'),
      onClick: () =>
        navigate({
          to: `/members/$memberId/edit`,
          params: {
            memberId: member.id,
          },
        }),
    },
    {
      label: t('action.confirm'),
      bold: true,
    },
  ]

  return (
    <MemberPageHeader breadcrumbItems={breadcrumbItems}>
      <MemberPageInnerLayout>
        <MemberConfirmView member={member} onBack={onBack} />
      </MemberPageInnerLayout>
    </MemberPageHeader>
  )
}
