import { t } from 'i18next'
import { z } from 'zod'

import { phoneRegex } from '~/validation/regex'

export const memberEditSchema = z.object({
  id: z.string(),
  name: z.string().min(1, {
    message: t('message.required', {
      entity: t('pages.members.label.name'),
    }),
  }),
  email: z
    .string()
    .min(1, {
      message: t('message.required', {
        entity: t('pages.members.label.email'),
      }),
    })
    .email(t('message.invalidEmail') ?? ''),
  role: z.coerce.number(),
  language: z.coerce.number(),
  phoneNumber: z
    .string()
    .optional()
    .refine(
      (val) => val === undefined || val === '' || phoneRegex.test(val),
      t('message.invalidPhoneNumber') ?? '',
    ),
  mobileNumber: z
    .string()
    .optional()
    .refine(
      (val) => val === undefined || val === '' || phoneRegex.test(val),
      t('message.invalidPhoneNumber') ?? '',
    ),
  userRoleSettings: z.array(
    z
      .array(
        z.object({
          label: z.string(),
          value: z.string(),
          planCode: z.string(),
        }),
      )
      .min(1, {
        message: t('message.min1', {
          entity: t('pages.members.label.userRole'),
        }),
      }),
  ),
})

export type MemberEditSchema = z.infer<typeof memberEditSchema>
