import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'

type Props = {
  children: React.ReactNode
}

export const MemberPageInnerLayout: React.FC<Props> = ({ children }) => {
  return (
    <Flex flexDirection="column" margin="auto" padding={Spacing[2]}>
      <Flex minWidth="960px" margin="auto">
        {children}
      </Flex>
    </Flex>
  )
}
