import { GoogleTagManager } from '@ecloud/tag'
import { Helmet, HelmetProvider } from 'react-helmet-async'

type HeadProps = {
  children: React.ReactNode
}

export const HeadProvider: React.FC<HeadProps> = ({ children }: HeadProps) => {
  return (
    <HelmetProvider>
      {import.meta.env.MODE === 'development' && (
        <Helmet>
          <link href="/favicon_local.svg" rel="icon" type="image/svg+xml" />
        </Helmet>
      )}
      <GoogleTagManager containerId={import.meta.env.VITE_GTM_CONTAINER_ID} />
      {children}
    </HelmetProvider>
  )
}
