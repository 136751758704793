import * as React from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import {
  Loading,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutBreadcrumbItem,
  SideMenuLayoutContentLayout,
} from '@enechain/ecloud-designsystem'

type Props = {
  children: React.ReactNode
  breadcrumbItems?: SideMenuLayoutBreadcrumbItem[]
}

export const MemberPageHeader: React.FC<Props> = ({
  children,
  breadcrumbItems: breadcrumbItemsProps,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  let breadcrumbItems: SideMenuLayoutBreadcrumbItem[] = [
    {
      label: t('pages.members.list'),
      onClick: () =>
        navigate({
          to: '/members',
        }),
    },
  ]
  if (breadcrumbItemsProps) {
    breadcrumbItems = [...breadcrumbItems, ...breadcrumbItemsProps]
  }
  breadcrumbItems = breadcrumbItems.map((item, i) => {
    return {
      ...item,
      bold: i + 1 === breadcrumbItems.length,
    }
  })

  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb items={breadcrumbItems} />
        </Flex>
      }
    >
      <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
    </SideMenuLayoutContentLayout>
  )
}
