import { useNavigate } from '@tanstack/react-router'
import { useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import { Member } from '~/gen/proto/bff/v1/model_pb'
import MemberEditView from '~/pages/Members/components/MemberEdit'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { MemberPageInnerLayout } from '~/pages/Members/components/layouts/Inner'
import { memberAtom } from '~/pages/Members/state/memberMutation'

export const NEW_MEMBER_KEY = 'newMember'

export const MemberCreate: React.FC = () => {
  const { t } = useTranslation()
  const member = new Member({
    id: NEW_MEMBER_KEY, // This is a temporary id for the new member
  })
  const editing = useAtomValue(memberAtom)[NEW_MEMBER_KEY] ?? member
  const resetEditingMember = useSetAtom(memberAtom)
  const navigate = useNavigate()
  const onNext = (): void => {
    void navigate({
      to: `/members/create/confirm`,
      params: {
        memberId: editing.id,
      },
    })
  }

  const onCancel = (): void => {
    void navigate({
      to: '/members',
    })
    resetEditingMember({})
  }

  return (
    <MemberPageHeader
      breadcrumbItems={[
        {
          label: t('action.add'),
        },
      ]}
    >
      <MemberPageInnerLayout>
        <MemberEditView member={editing} onCancel={onCancel} onNext={onNext} />
      </MemberPageInnerLayout>
    </MemberPageHeader>
  )
}
