import { createFileRoute } from '@tanstack/react-router'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { MemberEdit } from '~/pages/Members/edit'

export const Route = createFileRoute('/_authenticated/members/$memberId/edit')({
  component: () => (
    <OrganizationAdmin thorwError>
      <MemberEdit />
    </OrganizationAdmin>
  ),
})

export const MemberEditRoute = Route
