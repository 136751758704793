import { format } from 'date-fns'

import { Date as PbDate } from '~/gen/proto/bff/v1/model_pb'

class CustomDate {
  public date: Date
  constructor(date: Date) {
    this.date = date
  }
  public formatYYYYMMDD(): string {
    return format(this.date, 'yyyy-MM-dd')
  }
}

export function fromPbDate(date: PbDate): CustomDate {
  return new CustomDate(new Date(date.year, date.month - 1, date.day))
}
