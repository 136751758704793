import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useToast } from '@enechain/ecloud-designsystem'

import {
  getMemberProfile,
  uploadMemberImage,
} from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'

export function useUploadProfileImage(): ReturnType<typeof useMutation> {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  return useMutation(uploadMemberImage, {
    onSuccess: () => {
      showToast({
        toastMessage: t('pages.profile.action.uploadImage.success'),
        status: 'success',
      })
      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getMemberProfile),
      })
    },
    onError: () => {
      showToast({
        toastMessage: t('pages.profile.action.uploadImage.error'),
        status: 'error',
      })
    },
  })
}
