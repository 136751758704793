import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useToast } from '@enechain/ecloud-designsystem'

import {
  getMemberProfile,
  saveMemberProfile,
} from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'

export function useProfileMutation(): ReturnType<typeof useMutation> {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  return useMutation(saveMemberProfile, {
    onError: (error) => {
      showToast({
        toastMessage: error.message,
        status: 'error',
      })
    },
    onSuccess: () => {
      showToast({
        toastMessage: t('message.success_change', {
          entity: t('domain.profile'),
        }),
        status: 'success',
      })
      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getMemberProfile, {}),
      })
    },
  })
}
