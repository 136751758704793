import { createFileRoute } from '@tanstack/react-router'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { MemberConfirm } from '~/pages/Members/confirm'

export const Route = createFileRoute(
  '/_authenticated/members/$memberId/confirm',
)({
  component: () => (
    <OrganizationAdmin thorwError>
      <MemberConfirm />
    </OrganizationAdmin>
  ),
})

export const MemberConfirmRoute = Route
