import { Box, Flex, IconProps, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import {
  EClearSymbol,
  ECloudSymbol,
  ECompassSymbol,
  EScanSymbol,
  ESquareLiveSymbol,
  ESquareSymbol,
  FaChevronRightRegular,
  JcexSymbol,
} from '@enechain/ecloud-icons'

type Product = {
  name:
    | 'eSquare'
    | 'eSquare Live'
    | 'eScan'
    | 'eCompass'
    | 'eClear'
    | 'JCEX Market'
    | 'JCEX Data'
  svg: React.NamedExoticComponent<IconProps>
}

const products: Product[] = [
  {
    name: 'eSquare',
    svg: ESquareSymbol,
  },
  {
    name: 'eSquare Live',
    svg: ESquareLiveSymbol,
  },
  {
    name: 'eScan',
    svg: EScanSymbol,
  },
  {
    name: 'eCompass',
    svg: ECompassSymbol,
  },
  {
    name: 'eClear',
    svg: EClearSymbol,
  },
  {
    name: 'JCEX Market',
    svg: JcexSymbol,
  },
  {
    name: 'JCEX Data',
    svg: JcexSymbol,
  },
]

type Props = {
  name: string
  url: string
}

export const ProductLink: React.FC<Props> = ({ name, url }: Props) => {
  const LogoComponent = products.find((product) => product.name === name)?.svg
  return (
    <Flex
      justifyContent="space-between"
      width="282px"
      padding={Spacing[1]}
      textAlign="center"
      border="1px"
      borderColor={SemanticColors.Border['midEmphasis']}
      borderRadius={BorderRadius.radiiLg}
      cursor="pointer"
      onClick={() => void window.open(url)}
    >
      <Flex alignItems="center" gap={Spacing[2]}>
        {LogoComponent ? (
          <LogoComponent boxSize="40px" />
        ) : (
          <ECloudSymbol boxSize="40px" />
        )}
        <Text
          {...Typography.headerLg}
          color={SemanticColors.Text['highEmphasis']}
        >
          {name}
        </Text>
      </Flex>
      <Box padding={Spacing[2]}>
        <FaChevronRightRegular boxSize="20px" />
      </Box>
    </Flex>
  )
}
