import { Trans } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import { Link, SemanticColors, Spacing } from '@enechain/ecloud-designsystem'

import { Language, MemberRole } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'

export const MemberInfomation: React.FC = () => {
  const me = useLoginUser()
  const hasLicense = Object.keys(me.baseLicenseMap).length > 0
  const i18nkey = (): string => {
    if (!hasLicense) {
      return 'pages.members.description.forbidNoLicense'
    }

    if (me.role === MemberRole.ADMIN) {
      return 'pages.members.description.infomationForAdmin'
    }
    return 'pages.members.description.infomationForMember'
  }

  return (
    <Box
      padding={Spacing[4]}
      color={SemanticColors.Text.neutralMid}
      backgroundColor={SemanticColors.Surface.neutralLow}
    >
      <Trans
        components={{
          MailSetting: (
            <Link
              href={import.meta.env.VITE_ESQUARE_MAIL_SETTING_URL}
              isExternal
            />
          ),
          Inquiry: (
            <Link
              href={
                me.language === Language.ENGLISH
                  ? import.meta.env.VITE_ENG_INQUIRY_FORM_URL
                  : import.meta.env.VITE_INQUIRY_FORM_URL
              }
              isExternal
            />
          ),
        }}
        i18nKey={i18nkey()}
      />
    </Box>
  )
}
