import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  SemanticColors,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutBreadcrumbItem,
  SideMenuLayoutContentLayout,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import { ProductLink } from '~/pages/Home/components/ProductLink'
import { useListProducts } from '~/pages/Home/hooks/useProduct'

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const products = useListProducts()
  const breadcrumbItems: SideMenuLayoutBreadcrumbItem[] = [
    {
      label: t('pages.home.title'),
      bold: true,
    },
  ]

  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb items={breadcrumbItems} />
        </Flex>
      }
    >
      <Flex
        flexDirection="column"
        gap={Spacing[3]}
        width="960px"
        maxWidth="90%"
        margin="auto"
      >
        <Text
          {...Typography.headerMd}
          color={SemanticColors.Text['highEmphasis']}
          textAlign="left"
        >
          {t('pages.home.subtitle')}
        </Text>
        <Flex flexWrap="wrap" gap={Spacing[6]}>
          {products
            .filter((p) => p.isPublic)
            .map((p) => {
              return <ProductLink key={p.name} name={p.name} url={p.url} />
            })}
        </Flex>
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}
