// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file bff/v1/admin.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetMemberRequest, GetMemberResponse, SaveMemberRequest, SaveMemberResponse, SendInvitationRequest, SendInvitationResponse } from "./admin_pb.js";

/**
 * @generated from rpc bff.v1.BffAdminService.SendInvitation
 */
export const sendInvitation = {
  localName: "sendInvitation",
  name: "SendInvitation",
  kind: MethodKind.Unary,
  I: SendInvitationRequest,
  O: SendInvitationResponse,
  service: {
    typeName: "bff.v1.BffAdminService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffAdminService.GetMember
 */
export const getMember = {
  localName: "getMember",
  name: "GetMember",
  kind: MethodKind.Unary,
  I: GetMemberRequest,
  O: GetMemberResponse,
  service: {
    typeName: "bff.v1.BffAdminService"
  }
} as const;

/**
 * @generated from rpc bff.v1.BffAdminService.SaveMember
 */
export const saveMember = {
  localName: "saveMember",
  name: "SaveMember",
  kind: MethodKind.Unary,
  I: SaveMemberRequest,
  O: SaveMemberResponse,
  service: {
    typeName: "bff.v1.BffAdminService"
  }
} as const;
