import { t } from 'i18next'

import { Language, MemberRole, MemberStatus } from '~/gen/proto/bff/v1/model_pb'

export const translation = {
  language: (language: Language): string => {
    switch (language) {
      case Language.ENGLISH:
        return t('pages.members.value.english')
      case Language.JAPANESE:
        return t('pages.members.value.japanese')
      default:
        return ''
    }
  },
  role: (role: MemberRole): string => {
    switch (role) {
      case MemberRole.MEMBER:
        return t('pages.members.value.member')
      case MemberRole.ADMIN:
        return t('pages.members.value.admin')
      default:
        return ''
    }
  },
  status: (status: MemberStatus): string => {
    switch (status) {
      case MemberStatus.ACTIVE:
        return t('pages.members.value.active')
      case MemberStatus.DELETED:
        return t('pages.members.value.deleted')
      case MemberStatus.INVITED:
        return t('pages.members.value.invited')
      default:
        return ''
    }
  },
}
