import { useMutation } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { useToast } from '@enechain/ecloud-designsystem'

import { resetPassword } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'

export function useResetPassword(): ReturnType<typeof useMutation> {
  const { showToast } = useToast()
  const { t } = useTranslation()
  return useMutation(resetPassword, {
    onError: (error) => {
      showToast({
        toastMessage: error.message,
        status: 'error',
      })
    },
    onSuccess: () => {
      showToast({
        toastMessage: t('pages.profile.action.resetPassword.success'),
        status: 'success',
      })
    },
  })
}
