import i18next, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'

import ja from './locales/ja/translation.json'
import en from './locales/en/translation.json'

type I18nProps = {
  debug?: boolean
}

const resources: Resource = {
  ja: {
    common: ja,
  },
  en: {
    common: en,
  },
}

export function i18n({ debug = false }: I18nProps): typeof i18next {
  void i18next.use(initReactI18next).init({
    resources: resources,
    lng: 'ja',
    fallbackLng: 'ja',
    defaultNS: 'common',
    debug,
    interpolation: {
      escapeValue: false,
    },
  })
  return i18next
}
