import { useCallback } from 'react'

import { Interceptor } from '@connectrpc/connect'
import { TransportProvider } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useAccessToken } from '~/provider/hooks/useAccessToken'

export const AppProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { getAccessToken } = useAccessToken()
  const authInterceptor = useCallback<Interceptor>(
    (next) => async (req) => {
      const token = await getAccessToken()
      if (token != null && token.length > 0) {
        req.header.set('Authorization', `Bearer ${token}`)
      }
      return next(req)
    },
    [getAccessToken],
  )

  const transport = createConnectTransport({
    baseUrl: import.meta.env.VITE_API_URL,
    interceptors: [authInterceptor],
  })

  return (
    <TransportProvider transport={transport}>
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                retry: false,
                staleTime: 1000 * 60 * 5,
              },
            },
          })
        }
      >
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </TransportProvider>
  )
}
