import { Suspense } from 'react'

import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import {
  Loading,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'

import { ProfileEditForm } from '~/pages/Profile/components/ProfileEditForm'

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb
            items={[
              {
                label: t('pages.profile.title'),
                bold: true,
              },
            ]}
          />
        </Flex>
      }
    >
      <Flex
        flexDirection="column"
        gap={Spacing[6]}
        width="960px"
        maxWidth="90%"
        margin="auto"
        padding={Spacing[4]}
      >
        <Suspense fallback={<Loading />}>
          <ProfileEditForm />
        </Suspense>
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}
