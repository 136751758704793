import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { getOrganization } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { Organization } from '~/gen/proto/bff/v1/model_pb'

import { STALE_TIME } from './config'
import { useLoginUser } from './useLoginUser'

export function useLoginOrganization(): Organization {
  const me = useLoginUser()
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    getOrganization,
    { id: me.organizationId ?? '' },
    {
      select: (data) => data.organization,
      staleTime: STALE_TIME,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(
      t('message.fetchFail', { entity: t('domain.organization') }),
    )
  }
  return res.data
}
