// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file bff/v1/common.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Language, License, Member, Organization, Plan, Product, UserRole, UserRoleSetting } from "./model_pb.js";

/**
 * メンバープロフィール保存のリクエスト
 *
 * @generated from message bff.v1.SaveMemberProfileRequest
 */
export class SaveMemberProfileRequest extends Message<SaveMemberProfileRequest> {
  /**
   * メンバーID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 所属組織ID
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * メンバーの電話番号
   *
   * @generated from field: string phone_number = 3;
   */
  phoneNumber = "";

  /**
   * メンバーの携帯電話番号
   *
   * @generated from field: string mobile_number = 4;
   */
  mobileNumber = "";

  /**
   * メンバー名
   *
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * 言語設定
   *
   * @generated from field: bff.v1.Language language = 6;
   */
  language = Language.UNKNOWN;

  constructor(data?: PartialMessage<SaveMemberProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SaveMemberProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "language", kind: "enum", T: proto3.getEnumType(Language) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveMemberProfileRequest {
    return new SaveMemberProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveMemberProfileRequest {
    return new SaveMemberProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveMemberProfileRequest {
    return new SaveMemberProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveMemberProfileRequest | PlainMessage<SaveMemberProfileRequest> | undefined, b: SaveMemberProfileRequest | PlainMessage<SaveMemberProfileRequest> | undefined): boolean {
    return proto3.util.equals(SaveMemberProfileRequest, a, b);
  }
}

/**
 * メンバープロフィール保存のレスポンス
 *
 * @generated from message bff.v1.SaveMemberProfileResponse
 */
export class SaveMemberProfileResponse extends Message<SaveMemberProfileResponse> {
  /**
   * @generated from field: bff.v1.Member member = 1;
   */
  member?: Member;

  constructor(data?: PartialMessage<SaveMemberProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SaveMemberProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: Member },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveMemberProfileResponse {
    return new SaveMemberProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveMemberProfileResponse {
    return new SaveMemberProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveMemberProfileResponse {
    return new SaveMemberProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveMemberProfileResponse | PlainMessage<SaveMemberProfileResponse> | undefined, b: SaveMemberProfileResponse | PlainMessage<SaveMemberProfileResponse> | undefined): boolean {
    return proto3.util.equals(SaveMemberProfileResponse, a, b);
  }
}

/**
 * メンバープロフィール取得のリクエスト
 *
 * @generated from message bff.v1.GetMemberProfileRequest
 */
export class GetMemberProfileRequest extends Message<GetMemberProfileRequest> {
  constructor(data?: PartialMessage<GetMemberProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetMemberProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberProfileRequest {
    return new GetMemberProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberProfileRequest {
    return new GetMemberProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberProfileRequest {
    return new GetMemberProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberProfileRequest | PlainMessage<GetMemberProfileRequest> | undefined, b: GetMemberProfileRequest | PlainMessage<GetMemberProfileRequest> | undefined): boolean {
    return proto3.util.equals(GetMemberProfileRequest, a, b);
  }
}

/**
 * メンバープロフィール取得のレスポンス
 *
 * @generated from message bff.v1.GetMemberProfileResponse
 */
export class GetMemberProfileResponse extends Message<GetMemberProfileResponse> {
  /**
   * @generated from field: bff.v1.Member member = 1;
   */
  member?: Member;

  constructor(data?: PartialMessage<GetMemberProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetMemberProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: Member },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberProfileResponse {
    return new GetMemberProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberProfileResponse {
    return new GetMemberProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberProfileResponse {
    return new GetMemberProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberProfileResponse | PlainMessage<GetMemberProfileResponse> | undefined, b: GetMemberProfileResponse | PlainMessage<GetMemberProfileResponse> | undefined): boolean {
    return proto3.util.equals(GetMemberProfileResponse, a, b);
  }
}

/**
 * プロダクト一覧取得のリクエスト
 *
 * @generated from message bff.v1.ListProductsRequest
 */
export class ListProductsRequest extends Message<ListProductsRequest> {
  constructor(data?: PartialMessage<ListProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined, b: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined): boolean {
    return proto3.util.equals(ListProductsRequest, a, b);
  }
}

/**
 * プロダクト一覧取得のレスポンス
 *
 * @generated from message bff.v1.ListProductsResponse
 */
export class ListProductsResponse extends Message<ListProductsResponse> {
  /**
   * @generated from field: repeated bff.v1.Product products = 1;
   */
  products: Product[] = [];

  constructor(data?: PartialMessage<ListProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined, b: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined): boolean {
    return proto3.util.equals(ListProductsResponse, a, b);
  }
}

/**
 * 組織取得のリクエスト
 *
 * @generated from message bff.v1.GetOrganizationRequest
 */
export class GetOrganizationRequest extends Message<GetOrganizationRequest> {
  /**
   * 組織ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined, b: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationRequest, a, b);
  }
}

/**
 * 組織取得のレスポンス
 *
 * @generated from message bff.v1.GetOrganizationResponse
 */
export class GetOrganizationResponse extends Message<GetOrganizationResponse> {
  /**
   * 組織
   *
   * @generated from field: bff.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<GetOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined, b: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationResponse, a, b);
  }
}

/**
 * パスワードリセットのリクエスト
 *
 * @generated from message bff.v1.ResetPasswordRequest
 */
export class ResetPasswordRequest extends Message<ResetPasswordRequest> {
  constructor(data?: PartialMessage<ResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined, b: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordRequest, a, b);
  }
}

/**
 * パスワードリセットのレスポンス
 *
 * @generated from message bff.v1.ResetPasswordResponse
 */
export class ResetPasswordResponse extends Message<ResetPasswordResponse> {
  constructor(data?: PartialMessage<ResetPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ResetPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined, b: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined): boolean {
    return proto3.util.equals(ResetPasswordResponse, a, b);
  }
}

/**
 * ユーザーロール一覧取得のリクエスト
 *
 * @generated from message bff.v1.ListUserRolesRequest
 */
export class ListUserRolesRequest extends Message<ListUserRolesRequest> {
  /**
   * プランID
   *
   * @generated from field: int32 plan_id = 1;
   */
  planId = 0;

  constructor(data?: PartialMessage<ListUserRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListUserRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserRolesRequest {
    return new ListUserRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserRolesRequest {
    return new ListUserRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserRolesRequest {
    return new ListUserRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserRolesRequest | PlainMessage<ListUserRolesRequest> | undefined, b: ListUserRolesRequest | PlainMessage<ListUserRolesRequest> | undefined): boolean {
    return proto3.util.equals(ListUserRolesRequest, a, b);
  }
}

/**
 * ユーザーロール一覧取得のレスポンス
 *
 * @generated from message bff.v1.ListUserRolesResponse
 */
export class ListUserRolesResponse extends Message<ListUserRolesResponse> {
  /**
   * @generated from field: repeated bff.v1.UserRole user_roles = 1;
   */
  userRoles: UserRole[] = [];

  constructor(data?: PartialMessage<ListUserRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListUserRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_roles", kind: "message", T: UserRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserRolesResponse {
    return new ListUserRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserRolesResponse {
    return new ListUserRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserRolesResponse {
    return new ListUserRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserRolesResponse | PlainMessage<ListUserRolesResponse> | undefined, b: ListUserRolesResponse | PlainMessage<ListUserRolesResponse> | undefined): boolean {
    return proto3.util.equals(ListUserRolesResponse, a, b);
  }
}

/**
 * 契約しているライセンスと紐づくユーザーロール一覧取得のリクエスト
 *
 * @generated from message bff.v1.ListSelectableUserRolesRequest
 */
export class ListSelectableUserRolesRequest extends Message<ListSelectableUserRolesRequest> {
  constructor(data?: PartialMessage<ListSelectableUserRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListSelectableUserRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSelectableUserRolesRequest {
    return new ListSelectableUserRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSelectableUserRolesRequest {
    return new ListSelectableUserRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSelectableUserRolesRequest {
    return new ListSelectableUserRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSelectableUserRolesRequest | PlainMessage<ListSelectableUserRolesRequest> | undefined, b: ListSelectableUserRolesRequest | PlainMessage<ListSelectableUserRolesRequest> | undefined): boolean {
    return proto3.util.equals(ListSelectableUserRolesRequest, a, b);
  }
}

/**
 * 契約しているライセンスと紐づくユーザーロール一覧取得のレスポンス
 *
 * @generated from message bff.v1.ListSelectableUserRolesResponse
 */
export class ListSelectableUserRolesResponse extends Message<ListSelectableUserRolesResponse> {
  /**
   * @generated from field: repeated bff.v1.SelectableUserRole selectable_user_roles = 1;
   */
  selectableUserRoles: SelectableUserRole[] = [];

  constructor(data?: PartialMessage<ListSelectableUserRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListSelectableUserRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selectable_user_roles", kind: "message", T: SelectableUserRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSelectableUserRolesResponse {
    return new ListSelectableUserRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSelectableUserRolesResponse {
    return new ListSelectableUserRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSelectableUserRolesResponse {
    return new ListSelectableUserRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSelectableUserRolesResponse | PlainMessage<ListSelectableUserRolesResponse> | undefined, b: ListSelectableUserRolesResponse | PlainMessage<ListSelectableUserRolesResponse> | undefined): boolean {
    return proto3.util.equals(ListSelectableUserRolesResponse, a, b);
  }
}

/**
 * プランIDからプロダクトを取得するリクエスト
 *
 * @generated from message bff.v1.GetProductByPlanIdRequest
 */
export class GetProductByPlanIdRequest extends Message<GetProductByPlanIdRequest> {
  /**
   * プランID
   *
   * @generated from field: int32 plan_id = 1;
   */
  planId = 0;

  constructor(data?: PartialMessage<GetProductByPlanIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetProductByPlanIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductByPlanIdRequest {
    return new GetProductByPlanIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductByPlanIdRequest {
    return new GetProductByPlanIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductByPlanIdRequest {
    return new GetProductByPlanIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductByPlanIdRequest | PlainMessage<GetProductByPlanIdRequest> | undefined, b: GetProductByPlanIdRequest | PlainMessage<GetProductByPlanIdRequest> | undefined): boolean {
    return proto3.util.equals(GetProductByPlanIdRequest, a, b);
  }
}

/**
 * プランIDからプロダクトを取得するレスポンス
 *
 * @generated from message bff.v1.GetProductByPlanIdResponse
 */
export class GetProductByPlanIdResponse extends Message<GetProductByPlanIdResponse> {
  /**
   * @generated from field: bff.v1.Product product = 1;
   */
  product?: Product;

  constructor(data?: PartialMessage<GetProductByPlanIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetProductByPlanIdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: Product },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductByPlanIdResponse {
    return new GetProductByPlanIdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductByPlanIdResponse {
    return new GetProductByPlanIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductByPlanIdResponse {
    return new GetProductByPlanIdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductByPlanIdResponse | PlainMessage<GetProductByPlanIdResponse> | undefined, b: GetProductByPlanIdResponse | PlainMessage<GetProductByPlanIdResponse> | undefined): boolean {
    return proto3.util.equals(GetProductByPlanIdResponse, a, b);
  }
}

/**
 * プラン取得のリクエスト
 *
 * @generated from message bff.v1.GetPlanRequest
 */
export class GetPlanRequest extends Message<GetPlanRequest> {
  /**
   * プランID
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  constructor(data?: PartialMessage<GetPlanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetPlanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPlanRequest | PlainMessage<GetPlanRequest> | undefined, b: GetPlanRequest | PlainMessage<GetPlanRequest> | undefined): boolean {
    return proto3.util.equals(GetPlanRequest, a, b);
  }
}

/**
 * プラン取得のレスポンス
 *
 * @generated from message bff.v1.GetPlanResponse
 */
export class GetPlanResponse extends Message<GetPlanResponse> {
  /**
   * @generated from field: bff.v1.Plan plan = 1;
   */
  plan?: Plan;

  constructor(data?: PartialMessage<GetPlanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetPlanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan", kind: "message", T: Plan },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPlanResponse | PlainMessage<GetPlanResponse> | undefined, b: GetPlanResponse | PlainMessage<GetPlanResponse> | undefined): boolean {
    return proto3.util.equals(GetPlanResponse, a, b);
  }
}

/**
 * ライセンス別ユーザーロール
 *
 * @generated from message bff.v1.SelectableUserRole
 */
export class SelectableUserRole extends Message<SelectableUserRole> {
  /**
   * ライセンス
   *
   * @generated from field: bff.v1.License license = 1;
   */
  license?: License;

  /**
   * ユーザーロール
   *
   * @generated from field: repeated bff.v1.UserRoleSetting user_role_setting = 2;
   */
  userRoleSetting: UserRoleSetting[] = [];

  constructor(data?: PartialMessage<SelectableUserRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SelectableUserRole";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "license", kind: "message", T: License },
    { no: 2, name: "user_role_setting", kind: "message", T: UserRoleSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelectableUserRole {
    return new SelectableUserRole().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelectableUserRole {
    return new SelectableUserRole().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelectableUserRole {
    return new SelectableUserRole().fromJsonString(jsonString, options);
  }

  static equals(a: SelectableUserRole | PlainMessage<SelectableUserRole> | undefined, b: SelectableUserRole | PlainMessage<SelectableUserRole> | undefined): boolean {
    return proto3.util.equals(SelectableUserRole, a, b);
  }
}

/**
 * メンバー一覧取得のリクエスト
 *
 * @generated from message bff.v1.ListMembersRequest
 */
export class ListMembersRequest extends Message<ListMembersRequest> {
  constructor(data?: PartialMessage<ListMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined, b: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined): boolean {
    return proto3.util.equals(ListMembersRequest, a, b);
  }
}

/**
 * メンバー一覧取得のレスポンス
 *
 * @generated from message bff.v1.ListMembersResponse
 */
export class ListMembersResponse extends Message<ListMembersResponse> {
  /**
   * @generated from field: repeated bff.v1.Member members = 1;
   */
  members: Member[] = [];

  constructor(data?: PartialMessage<ListMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListMembersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "members", kind: "message", T: Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined, b: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined): boolean {
    return proto3.util.equals(ListMembersResponse, a, b);
  }
}

/**
 * メンバーの画像アップロードリクエスト
 *
 * @generated from message bff.v1.UploadMemberImageRequest
 */
export class UploadMemberImageRequest extends Message<UploadMemberImageRequest> {
  /**
   * 画像データ
   *
   * @generated from field: bytes image = 1;
   */
  image = new Uint8Array(0);

  constructor(data?: PartialMessage<UploadMemberImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UploadMemberImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadMemberImageRequest {
    return new UploadMemberImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadMemberImageRequest {
    return new UploadMemberImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadMemberImageRequest {
    return new UploadMemberImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadMemberImageRequest | PlainMessage<UploadMemberImageRequest> | undefined, b: UploadMemberImageRequest | PlainMessage<UploadMemberImageRequest> | undefined): boolean {
    return proto3.util.equals(UploadMemberImageRequest, a, b);
  }
}

/**
 * メンバーの画像アップロードレスポンス
 *
 * @generated from message bff.v1.UploadMemberImageResponse
 */
export class UploadMemberImageResponse extends Message<UploadMemberImageResponse> {
  /**
   * 画像URL
   *
   * @generated from field: string image_url = 1;
   */
  imageUrl = "";

  constructor(data?: PartialMessage<UploadMemberImageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UploadMemberImageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadMemberImageResponse {
    return new UploadMemberImageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadMemberImageResponse {
    return new UploadMemberImageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadMemberImageResponse {
    return new UploadMemberImageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadMemberImageResponse | PlainMessage<UploadMemberImageResponse> | undefined, b: UploadMemberImageResponse | PlainMessage<UploadMemberImageResponse> | undefined): boolean {
    return proto3.util.equals(UploadMemberImageResponse, a, b);
  }
}

