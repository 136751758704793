import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { getMember } from '~/gen/proto/bff/v1/admin-BffAdminService_connectquery'
import { Member } from '~/gen/proto/bff/v1/model_pb'

export function useMember(memberId: string): Member {
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    getMember,
    { memberId },
    {
      select: (data) => data.member,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.member') }))
  }
  const member = res.data
  if (!member.userRoleMap) {
    return res.data
  }
  member.userRoleCodes = Object.values(member.userRoleMap.settingMap).flatMap(
    (setting) => {
      return setting.settings
        .filter((role) => role.isSetting)
        .map((role) => role.userRoleCode)
    },
  )
  return member
}
