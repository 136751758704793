import * as React from 'react'

import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Translation } from 'react-i18next'

import { useDisclosure } from '@chakra-ui/react'

import { DataTable, Th } from '@enechain/ecloud-designsystem'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { Member } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'
import MembersTableRow, {
  MemberRow,
  memberTableColumns,
} from '~/pages/Members/components/MembersTable/MembersTableRow'
import ReSendInvtationModal from '~/pages/Members/components/MembersTable/ReSendInvitationModal'
import { useMembers } from '~/pages/Members/hooks/useMembers'

const MembersTable: React.FC = () => {
  const [resendReceiver, setResendReceiver] = React.useState<Member>(
    new Member({}),
  )
  const resendDisclosure = useDisclosure()
  const me = useLoginUser()
  const membersResponse = useMembers(me.organizationId)

  const data = React.useMemo(() => {
    return membersResponse.map((v) => {
      return new MemberRow(
        v,
        (
          <OrganizationAdmin>
            <MembersTableRow
              member={v}
              onClickResendInvitation={() => {
                setResendReceiver(v)
                resendDisclosure.onOpen()
              }}
            />
          </OrganizationAdmin>
        ),
      )
    })
  }, [membersResponse, setResendReceiver, resendDisclosure])

  const table = useReactTable({
    columns: memberTableColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const Table = React.useMemo(() => {
    return (
      <DataTable
        renderHeader={(header): JSX.Element => {
          return (
            <Translation key={header.column.id}>
              {(t) => (
                <Th sx={header.column.columnDef.meta}>
                  {header.column.id === 'rest'
                    ? ''
                    : t(`pages.members.label.${header.column.id}`)}
                </Th>
              )}
            </Translation>
          )
        }}
        table={table}
      />
    )
  }, [table, membersResponse])

  return (
    <>
      {Table}
      <ReSendInvtationModal
        isOpen={resendDisclosure.isOpen}
        member={resendReceiver}
        onClose={resendDisclosure.onClose}
      />
    </>
  )
}

export default React.memo(MembersTable)
