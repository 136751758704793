import * as React from 'react'

import { SemanticColors, Tag } from '@enechain/ecloud-designsystem'

import { MemberStatus } from '~/gen/proto/bff/v1/model_pb'
import { dict } from '~/pages/Members/dict'

type Props = {
  status: MemberStatus
}
export const MemberStatusTag: React.FC<Props> = ({ status }) => {
  return (
    <Tag
      color={SemanticColors.Text.highEmphasis}
      backgroundColor={
        status === MemberStatus.ACTIVE
          ? SemanticColors.Surface.positiveMid
          : SemanticColors.Surface.neutralMid
      }
    >
      {dict.status(status)}
    </Tag>
  )
}
