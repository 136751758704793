import * as React from 'react'

import { createConnectQueryKey } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import { Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Link,
  PrimaryButton,
  SecondaryButton,
  SemanticColors,
  Spacing,
  Typography,
  useToast,
} from '@enechain/ecloud-designsystem'
import { FaArrowUpRightFromSquareRegular } from '@enechain/ecloud-icons'

import { ProfilePicture } from '~/components/parts/ProfilePicture'
import { listMembers } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { License, Member } from '~/gen/proto/bff/v1/model_pb'
import i18n from '~/i18n'
import { NEW_MEMBER_KEY } from '~/pages/Members/create'
import { dict } from '~/pages/Members/dict'
import { useMemberMutation } from '~/pages/Members/hooks/useMemberMutation'
import { useSelectableUserRoles } from '~/pages/Members/hooks/useSelectableUserRoles'
import { memberAtom } from '~/pages/Members/state/memberMutation'
import { isUserRoleConfigurable } from '~/pages/Members/utils/license'

type Props = {
  member: Member
  onBack: () => void
}

const MemberConfirmView: React.FC<Props> = ({ member, onBack }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setEditingMember = useSetAtom(memberAtom)
  const { showToast } = useToast()
  const isCreate = member.id === NEW_MEMBER_KEY
  const onSuccess = (): void => {
    const successMessage = isCreate
      ? t('pages.members.action.inviteMember.success')
      : t('message.success_change', {
          entity: t('domain.member'),
        })

    showToast({
      toastMessage: successMessage,
      status: 'success',
    })
    setTimeout(() => {
      void navigate({
        to: '/members',
      })
      setEditingMember({})
    }, 5000)
  }
  const { mutate, isPending: isSavingMember } = useMemberMutation(
    onSuccess,
    member.id,
  )
  const queryClient = useQueryClient()
  const handleSubmit = (): void => {
    mutate({
      ...member,
      id: isCreate ? undefined : member.id,
    })
    void queryClient.invalidateQueries({
      queryKey: createConnectQueryKey(listMembers),
    })
  }

  const selectableUserRoles = useSelectableUserRoles()

  return (
    <Stack gap={Spacing[6]} width="100%">
      <Flex alignItems="center" gap={Spacing[6]} width="100%">
        {member.imageUrl !== '' && member.imageUrl !== undefined && (
          <ProfilePicture src={member.imageUrl} />
        )}
        <Flex flexDirection="column">
          <Text {...Typography.headerMd}>{member.name}</Text>
          <Text>{member.email}</Text>
        </Flex>
      </Flex>

      <Text {...Typography.headerMd}>{t('pages.members.info')}</Text>

      <Grid
        gridGap={Spacing[4]}
        gridTemplateColumns="320px 1fr"
        gridTemplateAreas={`
          'name vname'
          'email vemail'
          'role vrole'
          'language vlanguage'
          'phoneNumber vphoneNumber'
          'mobileNumber vmobileNumber'`}
        width="full"
        marginBottom={Spacing[2]}
        padding={Spacing[4]}
        border="1px"
        borderColor={SemanticColors.Border.lowEmphasis}
        borderRadius={BorderRadius.radiiMd}
      >
        <GridItem area="name" fontWeight="bold">
          {t('pages.members.label.name')}
        </GridItem>
        <GridItem area="vname">{member.name}</GridItem>

        <GridItem area="email" fontWeight="bold">
          {t('pages.members.label.email')}
        </GridItem>
        <GridItem area="vemail">{member.email}</GridItem>

        <GridItem area="role" fontWeight="bold">
          {t('pages.members.label.role')}
        </GridItem>
        <GridItem area="vrole">{dict.role(member.role)}</GridItem>

        <GridItem area="language" fontWeight="bold">
          {t('pages.members.label.language')}
        </GridItem>
        <GridItem area="vlanguage">{dict.language(member.language)}</GridItem>

        <GridItem area="phoneNumber" fontWeight="bold">
          {t('pages.members.label.phoneNumber')}
        </GridItem>
        <GridItem area="vphoneNumber">{member.phoneNumber}</GridItem>

        <GridItem area="mobileNumber" fontWeight="bold">
          {t('pages.members.label.mobileNumber')}
        </GridItem>
        <GridItem area="vmobileNumber">{member.mobileNumber}</GridItem>
      </Grid>

      <Flex
        flexDirection="column"
        gap={Spacing[4]}
        display={
          isUserRoleConfigurable(
            selectableUserRoles.map((v) => v.license ?? new License()),
          )
            ? 'flex'
            : 'none'
        }
      >
        <Text {...Typography.headerMd}>{t('pages.members.roleSettings')}</Text>
        <Grid
          gridGap={Spacing[4]}
          gridTemplateColumns="320px 1fr"
          width="full"
          padding={Spacing[4]}
          border="1px"
          borderColor={SemanticColors.Border.lowEmphasis}
          borderRadius={BorderRadius.radiiMd}
        >
          {selectableUserRoles.map((role) => {
            const { userRoleSetting, license } = role
            if (!license) {
              return null
            }
            if (
              license.isUserRoleConfigurable === false ||
              license.isProductPublic === false
            ) {
              return null
            }
            return (
              <React.Fragment key={license.planId}>
                <GridItem>
                  <Text fontWeight="bold">{`${license.productName} / ${i18n.language === 'en' ? license.engPlanName : license.planName}`}</Text>
                  <Link href={`/user_roles/${license.planId}`} target="blank">
                    <Flex alignItems="center" gap={Spacing[1]}>
                      {t('pages.members.link.user_role_page')}
                      <FaArrowUpRightFromSquareRegular />
                    </Flex>
                  </Link>
                </GridItem>
                <GridItem>
                  {userRoleSetting
                    .filter((role) =>
                      member.userRoleCodes.some(
                        (code) => code === role.userRoleCode,
                      ),
                    )
                    .map((r) => {
                      return i18n.language === 'en'
                        ? r.userRoleEngName
                        : r.userRoleName
                    })
                    .join(', ')}
                </GridItem>
              </React.Fragment>
            )
          })}
        </Grid>
      </Flex>
      <Flex justifyContent="right" gap={Spacing[2]} width="100%">
        <SecondaryButton isDisabled={isSavingMember} onClick={onBack}>
          {t('action.back')}
        </SecondaryButton>
        <PrimaryButton isLoading={isSavingMember} onClick={handleSubmit}>
          {isCreate
            ? t('pages.members.action.inviteMember.confirm')
            : t('action.save')}
        </PrimaryButton>
      </Flex>
    </Stack>
  )
}

export default React.memo(MemberConfirmView)
