import * as React from 'react'

import { SecondaryButton, fontSizes } from '@enechain/ecloud-designsystem'
import { FaPaperPlaneRegular } from '@enechain/ecloud-icons'

type Props = {
  onClick: () => void
  children: React.ReactNode
}

const SendInvitationButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <SecondaryButton
      fontWeight="bold"
      leftIcon={<FaPaperPlaneRegular boxSize={fontSizes['2xl']} />}
      onClick={onClick}
    >
      {children}
    </SecondaryButton>
  )
}

export default React.memo(SendInvitationButton)
