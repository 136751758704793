import { createFileRoute } from '@tanstack/react-router'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { MemberCreate } from '~/pages/Members/create'

export const Route = createFileRoute('/_authenticated/members/create/')({
  component: () => {
    return (
      <OrganizationAdmin thorwError>
        <MemberCreate />
      </OrganizationAdmin>
    )
  },
})
