import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { listMembers } from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'
import { Member } from '~/gen/proto/bff/v1/model_pb'

export function useMembers(organizationId: string): Member[] {
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    listMembers,
    { organizationId: organizationId },
    {
      select: (data) => data.members,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.member') }))
  }
  return res.data
}
