import * as React from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import { Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Link,
  SemanticColors,
  Spacing,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaArrowUpRightFromSquareRegular } from '@enechain/ecloud-icons'

import { ProfilePicture } from '~/components/parts/ProfilePicture'
import { Language, Member } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'
import { dict } from '~/pages/Members/dict'
import { memberAtom } from '~/pages/Members/state/memberMutation'
import { isUserRoleConfigurable } from '~/pages/Members/utils/license'

type Props = {
  member: Member
}
const MemberDetailView: React.FC<Props> = ({ member }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setMember = useSetAtom(memberAtom)
  const me = useLoginUser()

  const onClickEdit = (): void => {
    setMember({
      [member.id]: member,
    })
    void navigate({
      to: '/members/$memberId/edit',
      params: { memberId: member.id },
    })
  }

  const hasLicense = Object.keys(me.baseLicenseMap).length > 0

  return (
    <Stack gap={Spacing[6]} width="100%">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Flex alignItems="center" gap={Spacing[6]}>
          <ProfilePicture src={member.imageUrl} />
          <Flex flexDirection="column">
            <Text {...Typography.headerMd}>{member.name}</Text>
            <Text>{member.email}</Text>
          </Flex>
        </Flex>
        {hasLicense && (
          <TertiaryButton onClick={onClickEdit}>
            {t('action.edit')}
          </TertiaryButton>
        )}
      </Flex>

      <Text {...Typography.headerMd}>{t('pages.members.info')}</Text>
      <Grid
        gridGap={Spacing[4]}
        gridTemplateColumns="320px 1fr"
        gridTemplateAreas={`
          'name vname'
          'email vemail'
          'role vrole'
          'language vlanguage'
          'phoneNumber vphoneNumber'
          'mobileNumber vmobileNumber'`}
        width="full"
        marginBottom={Spacing[2]}
        padding={Spacing[4]}
        border="1px"
        borderColor={SemanticColors.Border.lowEmphasis}
        borderRadius={BorderRadius.radiiMd}
      >
        <GridItem area="name" fontWeight="bold">
          {t('pages.members.label.name')}
        </GridItem>
        <GridItem area="vname">{member.name}</GridItem>

        <GridItem area="email" fontWeight="bold">
          {t('pages.members.label.email')}
        </GridItem>
        <GridItem area="vemail">{member.email}</GridItem>

        <GridItem area="role" fontWeight="bold">
          {t('pages.members.label.role')}
        </GridItem>
        <GridItem area="vrole">{dict.role(member.role)}</GridItem>

        <GridItem area="language" fontWeight="bold">
          {t('pages.members.label.language')}
        </GridItem>
        <GridItem area="vlanguage">{dict.language(member.language)}</GridItem>

        <GridItem area="phoneNumber" fontWeight="bold">
          {t('pages.members.label.phoneNumber')}
        </GridItem>
        <GridItem area="vphoneNumber">{member.phoneNumber}</GridItem>

        <GridItem area="mobileNumber" fontWeight="bold">
          {t('pages.members.label.mobileNumber')}
        </GridItem>
        <GridItem area="vmobileNumber">{member.mobileNumber}</GridItem>
      </Grid>

      {isUserRoleConfigurable(Object.values(me.baseLicenseMap)) && (
        <Flex flexDirection="column" gap={Spacing[4]}>
          <Text {...Typography.headerMd}>
            {t('pages.members.roleSettings')}
          </Text>
          <Grid
            gridGap={Spacing[4]}
            gridTemplateColumns="320px 1fr"
            width="full"
            padding={Spacing[4]}
            border="1px"
            borderColor={SemanticColors.Border.lowEmphasis}
            borderRadius={BorderRadius.radiiMd}
          >
            {Object.entries(member.userRoleMap?.settingMap ?? {}).map(
              ([key, value]) => {
                const license = member.baseLicenseMap[key]
                if (!license) {
                  return null
                }
                if (
                  license.isUserRoleConfigurable === false ||
                  license.isProductPublic === false
                ) {
                  return null
                }
                return (
                  <React.Fragment key={key}>
                    <GridItem>
                      <Text fontWeight="bold">{`${license.productName} /  ${me.language === Language.ENGLISH ? license.engPlanName : license.planName}`}</Text>
                      <Link href={`/user_roles/${license.planId}`} isExternal>
                        <Flex alignItems="center" gap={Spacing[1]}>
                          {t('pages.members.link.user_role_page')}
                          <FaArrowUpRightFromSquareRegular />
                        </Flex>
                      </Link>
                    </GridItem>
                    <GridItem>
                      {value.settings
                        .filter((s) => s.isSetting)
                        .map((role) =>
                          me.language === Language.ENGLISH
                            ? role.userRoleEngName
                            : role.userRoleName,
                        )
                        .join(', ')}
                    </GridItem>
                  </React.Fragment>
                )
              },
            )}
          </Grid>
        </Flex>
      )}
    </Stack>
  )
}

export default React.memo(MemberDetailView)
