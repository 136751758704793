import { createFileRoute } from '@tanstack/react-router'

import { OrganizationAdmin } from '~/components/authorize/OrganizationAdmin'
import { MemberCreateConfirm } from '~/pages/Members/createConfirm'

export const Route = createFileRoute('/_authenticated/members/create/confirm')({
  component: () => (
    <OrganizationAdmin thorwError>
      <MemberCreateConfirm />
    </OrganizationAdmin>
  ),
})
