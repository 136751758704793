import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  Loading,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import i18n from '~/i18n'
import { UserRolesTable } from '~/pages/UserRoles/components/UserRolesTable'
import { usePlan } from '~/pages/UserRoles/hooks/usePlan'
import { UserRoleDetailRoute } from '~/routes/_authenticated/user_roles/$planId'

export const UserRoleDetail: React.FC = () => {
  const { t } = useTranslation()
  const planId = UserRoleDetailRoute.useParams().planId
  const plan = usePlan(Number(planId))
  const planName = i18n.language === 'ja' ? plan.name : plan.engName

  return (
    <SideMenuLayoutContentLayout
      header={
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <SideMenuLayoutBreadcrumb
            items={[
              {
                label: t('pages.user_roles.title', { planName: planName }),
                bold: true,
              },
            ]}
          />
        </Flex>
      }
    >
      <React.Suspense fallback={<Loading />}>
        <Flex
          flexDirection="column"
          gap={Spacing[6]}
          width="60%"
          margin="auto"
          padding={Spacing[4]}
        >
          <Text {...Typography.headerLg}>
            {t('pages.user_roles.title', { planName: planName })}
          </Text>
          <Text {...Typography.headerMd}>{t('pages.user_roles.subtitle')}</Text>
          <UserRolesTable />
        </Flex>
      </React.Suspense>
    </SideMenuLayoutContentLayout>
  )
}
