import * as React from 'react'

import { useNavigate } from '@tanstack/react-router'

import { PageError } from '@enechain/ecloud-designsystem'

import { MemberRole } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'

type Props = {
  children: React.ReactNode
  thorwError?: boolean
}

export const OrganizationAdmin: React.FC<Props> = ({
  children,
  thorwError,
}) => {
  const me = useLoginUser()
  const navigate = useNavigate()
  if (me?.role !== MemberRole.ADMIN) {
    if (thorwError) {
      return (
        <PageError
          kind="403"
          onNavigate={() =>
            navigate({
              to: '/',
            })
          }
        />
      )
      throw new Error('You are not an organization admin')
    }
    return null
  }
  return children
}
