import * as React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, FormControl, Stack, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Input,
  Label,
  PrimaryButton,
  RadioButton,
  SemanticColors,
  Spacing,
  Term,
  Typography,
} from '@enechain/ecloud-designsystem'
import { FaTriangleExclamationRegular } from '@enechain/ecloud-icons'

import CancelButton from '~/components/parts/CancelButton'
import { ProfilePicture } from '~/components/parts/ProfilePicture'
import {
  Language,
  License,
  Member,
  MemberRole,
  UserRoleSetting,
} from '~/gen/proto/bff/v1/model_pb'
import {
  MemberEditSchema,
  memberEditSchema,
} from '~/pages/Members/components/MemberEdit/memberEditSchema'
import { useSelectableUserRoles } from '~/pages/Members/hooks/useSelectableUserRoles'
import { useSetMember } from '~/pages/Members/state/memberMutation'
import { isUserRoleConfigurable } from '~/pages/Members/utils/license'
import { validateEmptyNumber } from '~/validation/phonenumber'

import { UserRoleSelect } from './UserRoleSelect'

type Props = {
  member: Member
  onNext: () => void
  onCancel: () => void
}

const MemberEditView: React.FC<Props> = ({ member, onNext, onCancel }) => {
  const { t } = useTranslation()
  const setEditingMember = useSetMember(member)
  const userRoleSetting = useSelectableUserRoles()

  const formMethods = useForm<MemberEditSchema>({
    resolver: zodResolver(memberEditSchema),
    defaultValues: {
      id: member.id,
      role:
        member.role === MemberRole.UNKNOWN ? MemberRole.MEMBER : member.role,
      language:
        member.language === Language.UNKNOWN
          ? Language.JAPANESE
          : member.language,
    },
  })
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = formMethods
  const onSubmit = (data: MemberEditSchema): void => {
    if (
      !validateEmptyNumber(
        {
          phoneNumber: { old: member.phoneNumber, newVal: data.phoneNumber },
          mobileNumber: { old: member.mobileNumber, newVal: data.mobileNumber },
        },
        setError,
      )
    ) {
      return
    }
    setEditingMember({
      ...data,
    })
    void onNext()
  }

  return (
    <Stack gap={Spacing[6]} width="100%">
      <Flex alignItems="center" gap={Spacing[6]}>
        {member.imageUrl && <ProfilePicture src={member.imageUrl} />}
        <Flex flexDirection="column">
          <Text {...Typography.headerMd}>{member.name}</Text>
          <Text>{member.email}</Text>
        </Flex>
      </Flex>
      <Text {...Typography.headerMd}>{t('pages.members.info')}</Text>
      <Flex
        flexDirection="column"
        gap={Spacing[4]}
        padding={`${Spacing[6]} ${Spacing[4]}`}
        border="1px"
        borderColor={SemanticColors.Border.lowEmphasis}
        borderRadius={BorderRadius.radiiMd}
      >
        <Term>
          <Label required>{t('pages.members.label.name')}</Label>
          <Input
            defaultValue={member.name}
            placeholder={t('pages.members.placeholder.name')}
            {...register('name')}
            width="50%"
            error={errors.name?.message}
          />
        </Term>
        <Term>
          <Label required>{t('pages.members.label.email')}</Label>
          <Input
            defaultValue={member.email}
            {...register('email')}
            width="50%"
            error={errors.email?.message}
          />
          <Flex
            padding={Spacing[4]}
            {...Typography.textMd}
            alignItems="center"
            gap={Spacing[2]}
            color={SemanticColors.Text.warningMid}
            fontWeight="bold"
            backgroundColor={SemanticColors.Surface.warningLow}
          >
            <FaTriangleExclamationRegular
              boxSize="24px"
              color={SemanticColors.Object.warningHigh}
            />
            <Text>{t('pages.members.description.forbidGroupAddress')}</Text>
          </Flex>
        </Term>
        <FormControl>
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value } }): React.ReactElement => (
              <Term>
                <Label required>{t('pages.members.label.role')}</Label>
                <RadioButton
                  defaultValue={member.role.toString()}
                  onChange={onChange}
                  radios={[
                    {
                      label: t('pages.members.value.admin'),
                      value: MemberRole.ADMIN.toString(),
                    },
                    {
                      label: t('pages.members.value.member'),
                      value: MemberRole.MEMBER.toString(),
                    },
                  ]}
                  value={value.toString()}
                />
              </Term>
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            name="language"
            render={({ field: { onChange, value } }): React.ReactElement => (
              <Term>
                <Label required>{t('pages.members.label.language')}</Label>
                <RadioButton
                  defaultValue={member.language.toString()}
                  onChange={onChange}
                  radios={[
                    {
                      label: t('pages.members.value.japanese'),
                      value: Language.JAPANESE.toString(),
                    },
                    {
                      label: t('pages.members.value.english'),
                      value: Language.ENGLISH.toString(),
                    },
                  ]}
                  value={value.toString()}
                />
              </Term>
            )}
          />
        </FormControl>

        <Term>
          <Label>{t('pages.members.label.phoneNumber')}</Label>
          <Input
            defaultValue={member.phoneNumber}
            {...register('phoneNumber')}
            width="50%"
            error={errors.phoneNumber?.message}
          />
        </Term>

        <Term>
          <Label>{t('pages.members.label.mobileNumber')}</Label>
          <Input
            defaultValue={member.mobileNumber}
            {...register('mobileNumber')}
            width="50%"
            error={errors.mobileNumber?.message}
          />
        </Term>
      </Flex>

      <Flex
        flexDirection="column"
        gap={Spacing[2]}
        display={
          isUserRoleConfigurable(
            userRoleSetting.map((v) => v.license ?? new License()),
          )
            ? 'flex'
            : 'none'
        }
      >
        <Text {...Typography.headerMd}>{t('pages.members.roleSettings')}</Text>

        <Flex
          flexDirection="column"
          gap={Spacing[4]}
          padding={`${Spacing[6]} ${Spacing[4]}`}
          border="1px"
          borderColor={SemanticColors.Border.lowEmphasis}
          borderRadius={BorderRadius.radiiMd}
        >
          {userRoleSetting.map((role, i) => {
            const { userRoleSetting, license } = role
            if (!license) {
              return null
            }
            const settings = userRoleSetting.map((setting) => {
              const isSetting = member.userRoleCodes.some(
                (code) => setting.userRoleCode === code,
              )
              return new UserRoleSetting({
                ...setting,
                isSetting: isSetting,
              })
            })
            return (
              <UserRoleSelect
                key={license.planCode}
                formMethods={formMethods}
                index={i}
                license={license}
                userRoleSetting={settings}
              />
            )
          })}
        </Flex>
      </Flex>
      <Flex justifyContent="right" gap={Spacing[2]} width="100%">
        <CancelButton onClick={onCancel} />
        <PrimaryButton onClick={handleSubmit(onSubmit)}>
          {t('action.next')}
        </PrimaryButton>
      </Flex>
    </Stack>
  )
}

export default React.memo(MemberEditView)
