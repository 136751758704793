import React, { Suspense } from 'react'

import {
  RouterProvider as TanStackRouteProvider,
  createRouter,
} from '@tanstack/react-router'

import { PageError } from '@enechain/ecloud-designsystem'

import FullLoadingView from '~/components/layout/FullLoadingView'
import { routeTree } from '~/routes/gen/routeTree.gen'

const router = createRouter({
  routeTree,
  caseSensitive: true,
  notFoundMode: 'root',
  defaultNotFoundComponent: () => <PageError kind="404" />,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const RouteProvider: React.FC = () => {
  return <TanStackRouteProvider router={router} />
}
export const AppRouteProvider: React.FC = () => {
  return (
    <Suspense fallback={<FullLoadingView />}>
      <RouteProvider />
    </Suspense>
  )
}
