import { useLocation, useNavigate } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

import { SideMenuLayoutBreadcrumbItem } from '@enechain/ecloud-designsystem'

import MemberConfirmView from '~/pages/Members/components/MemberConfirm'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { MemberPageInnerLayout } from '~/pages/Members/components/layouts/Inner'
import { NEW_MEMBER_KEY } from '~/pages/Members/create'
import { memberAtom } from '~/pages/Members/state/memberMutation'

export const MemberCreateConfirm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const editing = useAtomValue(memberAtom)
  const member = editing[NEW_MEMBER_KEY]

  if (!member) {
    // 確認画面へ直接アクセスするかリロードした場合は、表示データがないため、別ページへリダイレクトする
    window.open(`/members/create`, '_self')
  }

  const location = useLocation()
  window.onbeforeunload = (e: BeforeUnloadEvent): void => {
    if (location.pathname === `/members/create/confirm`) {
      e.preventDefault()
    }
  }

  const onBack = (): void => {
    void navigate({
      to: `/members/create`,
    })
  }
  const breadcrumbItems: SideMenuLayoutBreadcrumbItem[] = [
    {
      label: member.name,
      onClick: () =>
        navigate({
          to: `/members/create`,
        }),
    },
    {
      label: t('action.confirm'),
      bold: true,
    },
  ]

  return (
    <MemberPageHeader breadcrumbItems={breadcrumbItems}>
      <MemberPageInnerLayout>
        <MemberConfirmView member={member} onBack={onBack} />
      </MemberPageInnerLayout>
    </MemberPageHeader>
  )
}
