import MemberDetailView from '~/pages/Members/components/MemberDetail'
import { MemberPageHeader } from '~/pages/Members/components/layouts/Header'
import { MemberPageInnerLayout } from '~/pages/Members/components/layouts/Inner'
import { MemberDetailRoute } from '~/routes/_authenticated/members/$memberId'

import { useMember } from './hooks/useMember'

export const MemberDetail: React.FC = () => {
  const memberId = MemberDetailRoute.useParams().memberId
  const member = useMember(memberId)

  return (
    <MemberPageHeader
      breadcrumbItems={[
        {
          label: member.name,
        },
      ]}
    >
      <MemberPageInnerLayout>
        <MemberDetailView member={member} />
      </MemberPageInnerLayout>
    </MemberPageHeader>
  )
}
